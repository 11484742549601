// This listAllObject is bascily the same as a listAll from grpc utils.
// But, it will not return god forsaken fieldsMap in case of nested structs

class MaxSentRequestsError extends Error {
  constructor(message) {
    super(message);
    this.name = "MaxSentRequestsError";
  }
}

const MAX_REQUESTS_NB = 10000;

export async function listAllObjects(
  socioGrpcApiPromiseClient,
  methodName,
  request,
  metadata
) {
  const inputPagination =
    "pagination" in metadata ? JSON.parse(metadata.pagination) : {};
  inputPagination.page = 1;
  const paginatedMetadata = {
    ...metadata,
    pagination: JSON.stringify(inputPagination),
  };
  // First request will determine numbers of page to fetch
  const firstResponse = await socioGrpcApiPromiseClient[methodName](request, {
    headers: paginatedMetadata,
  });
  const firstResults = firstResponse.results;
  if (!("count" in firstResponse) || isNaN(firstResponse.count)) {
    throw Error(
      "Expected a 'count' field with integer value from 'list' response"
    );
  }
  const expectedObjectNb = firstResponse.count;
  if (expectedObjectNb === firstResults.length) {
    return firstResults;
  }
  // Compute number of page to fetch from 'count' field
  const nbOfPageExpected = Math.ceil(expectedObjectNb / firstResults.length);
  if (nbOfPageExpected >= MAX_REQUESTS_NB) {
    throw MaxSentRequestsError(
      `Cannot exceed ${MAX_REQUESTS_NB} requests on 'listAllObjects' method.`
    );
  }
  // Build promise requests from page two to computed end of page
  const grpcPromiseList = [];
  for (let index = 2; index <= nbOfPageExpected; index++) {
    inputPagination.page = index;
    const paginatedMetadata = {
      ...metadata,
      pagination: JSON.stringify(inputPagination),
    };
    grpcPromiseList.push(
      socioGrpcApiPromiseClient[methodName](request, {
        headers: paginatedMetadata,
      })
    );
  }
  const promiseResults = await Promise.all(grpcPromiseList);
  const promiseResultsContent = promiseResults.map(
    (response) => response.results
  );
  const concatArray = (current, next) => current.concat(next);
  return promiseResultsContent.reduce(concatArray, firstResults);
}
