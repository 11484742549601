import RxDb from "@/rxdb/utils.js";
import { schemas } from "@/rxdb/schemas";

/**
 * @typedef {import("rxdb").RxCollection} RxCollection
 */

/**
 * @callback RxCollectionCallback
 * @param {RxCollection} collection
 */

export const useRxdbCollection = (collectionName) => {
  const db = RxDb.getInstance();
  const collectionSchema = schemas[collectionName];

  const isCollectionLive = () => {
    return typeof db.collections[collectionName] !== "undefined";
  };
  const instanceCollection = async () => {
    await db.addCollections({ [collectionName]: collectionSchema });
  };

  /**
   *
   * @param {RxCollectionCallback} callback
   * @param {Boolean} cleanup
   * @returns
   */
  const execOnCollection = async (callback, cleanup = false) => {
    if (!isCollectionLive()) {
      await instanceCollection();
    }

    try {
      const result = await callback(db.collections[collectionName]);
      if (cleanup) await db.collections[collectionName].destroy();
      return result;
    } catch (error) {
      console.error(`${collectionName} error trying to ${callback}`, error);
      return [];
    }
  };
  return {
    execOnCollection,
  };
};
