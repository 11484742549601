export const formatDocData = (obj) => {
  return {
    uuid: obj.uuid,
    servicesRelatedName: obj.servicesRelatedNameList,
    strongRelations: obj.strongRelationsList,
    tags: obj.tagsList,
    name: obj.name,
    docType: obj.docType?.length ? obj.docType : null,
    source: obj.source,
    ref: obj.ref,
    version: obj.version,
    datetime: obj.datetime,
    datetimeFormat: obj.datetimeFormat,
    isFromLegacySoftware: obj.isFromLegacySoftware,
    isActive: obj.isActive,
    oldOriginId: obj.oldOriginId,
    origin: obj.origin,
    originId: obj.originId?.toString(),
    gedProjectId: obj.gedProjectId.toString(),
    metaDatas: obj.metaDatas,
  };
};

// yes this a duplicat of DocumentUpload from grpc-api
// yes this is only made to accomodate for connect client
export class DocumentUploader {
  constructor(
    document,
    documentCustom,
    file,
    fileName,
    chunkSize,
    standbyUpload,
    standbyUploadClient,
    documentClient
  ) {
    this.standbyUploadClient = standbyUploadClient;
    this.documentClient = documentClient;
    this.reader = new FileReader();
    this.file = file;
    this.fileName = fileName;
    this.uploadProgress = 0;
    this.chunkSize = chunkSize; // 4194304; => max grpc
    this.standbyUpload = standbyUpload;
    this.document = document;
    this.documentCustom = documentCustom;
  }

  /**
   * @param {String} standbyUpload standbyUpload uuid
   * @return {Number} return progression 0-100 % , if uploadProgress retured -1 it's mean an issue has been raised
   */
  get progression() {
    return this.uploadProgress;
  }

  async createStandbyUpload() {
    try {
      const request = { fileName: this.fileName };

      const response = await this.standbyUploadClient.create(request, {});
      this.standbyUpload = response.uuid;
      return this.standbyUpload;
    } catch (e) {
      console.error("upload error", e);
    }
  }

  formatDocument() {
    this.document.metaDatas = { ...this.document.metaDatas };

    const request = { ...this.document };
    return request;
  }

  formatDocumentCustom() {
    const request = {
      ...this.documentCustom,
    };
    return request;
  }

  async uploadDoc(fileAsBytes, callback) {
    const requestStandByUpload = {
      uuid: this.standbyUpload,
    };

    const request = {
      standbyUpload: requestStandByUpload,
      done: false,
    };

    for (let i = 0; i < fileAsBytes.length; i += this.chunkSize) {
      request.chunk = fileAsBytes.slice(i, i + this.chunkSize);

      if (fileAsBytes.length) {
        this.uploadProgress = Math.round((i / fileAsBytes.length) * 100);
      }

      try {
        await this.documentClient.uploadDocument(request, {});
        callback(
          this.progression,
          fileAsBytes.slice(i + this.chunkSize + 1),
          null
        );
      } catch (e) {
        console.error("upload failed 1", e);
        throw new Error("Upload failed");
      }
    }

    try {
      const response = await this.documentClient.uploadDocument(
        {
          document: this.formatDocument(),
          documentCustom: this.formatDocumentCustom(),
          done: true,
          standbyUpload: { uuid: this.standbyUpload },
        },
        {}
      );
      this.uploadProgress = 100;
      const responseObject = response;
      callback(this.progression, null, responseObject);
      return responseObject;
    } catch (e) {
      this.uploadProgress = -1;
      callback(this.progression, null, null);
      console.error("upload failed", e);
    }
  }

  async upload(callback = () => {}) {
    let rawFile;

    this.standbyUpload = this.standbyUpload
      ? this.standbyUpload
      : await this.createStandbyUpload();
    this.reader.onloadend = async () => {
      rawFile = this.reader.result;
      const bytes = new Uint8Array(rawFile);
      const response = await this.uploadDoc(bytes, callback);
      return response;
    };
    this.reader.readAsArrayBuffer(this.file);
  }
}
//
