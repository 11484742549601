import { addRxPlugin, createRxDatabase } from "rxdb";
import { getRxStorageDexie } from "rxdb/plugins/storage-dexie";
import { wrappedValidateAjvStorage } from "rxdb/plugins/validate-ajv";

import { RxDBUpdatePlugin } from "rxdb/plugins/update";
import { RxDBQueryBuilderPlugin } from "rxdb/plugins/query-builder";
import { RxDBMigrationSchemaPlugin } from "rxdb/plugins/migration-schema";
import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode";
import { RxDBStatePlugin } from "rxdb/plugins/state";
import { RxDBAttachmentsPlugin } from "rxdb/plugins/attachments";

const apiEnv = process.env.VUE_APP_SOCIO_GRPC_API_ENV;

class ErrorWithRxdbInstance extends Error {
  constructor(error, dbInstance) {
    super(error);
    this.db = dbInstance;
  }
}

const getStorage = () => {
  const storage = wrappedValidateAjvStorage({
    storage: getRxStorageDexie(),
  });
  return storage;
};

export const createDatabase = async (schemas) => {
  if (apiEnv === "local") {
    addRxPlugin(RxDBDevModePlugin);
  }
  addRxPlugin(RxDBUpdatePlugin);
  addRxPlugin(RxDBQueryBuilderPlugin);
  addRxPlugin(RxDBMigrationSchemaPlugin);
  addRxPlugin(RxDBAttachmentsPlugin);
  addRxPlugin(RxDBStatePlugin);
  const storage = getStorage();
  const db = await createRxDatabase({
    name: "amos",
    storage,
    ignoreDuplicate: true,
  });
  try {
    await db.addCollections(schemas);
  } catch (e) {
    throw new ErrorWithRxdbInstance(e, db);
  }
  return db;
};
