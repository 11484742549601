import { useService } from "@/rxdbWorker/tokenManager";

export const pingHealthCheck = async () => {
  const healthClient = useService("amos_back", { healthcheck: true });
  return await healthClient.check();
};

export const watchHealthCheck = async (callback) => {
  const healthClient = useService("amos_back", { healthcheck: true });
  const test = healthClient.watch(callback);
  for (const key in test) {
    console.log(key, test[key]);
  }
};
